<template>
  <v-container>
    <ScheduleViewOptions v-if="adminView" />
    <div v-if="adminView && numHiddenEvents > 0">
      <v-checkbox v-model="showHiddenEvents" :label="`Show hidden events? (${numHiddenEvents})`"></v-checkbox>
    </div>
    <template v-if="ambassadorView">
      <h1>
        Ambassador: {{ targetAmbassadorData.firstName }} {{ targetAmbassadorData.lastName }}

        <div>
          <v-btn v-if="!mergedView" color="primary" @click="mergedView = true">
            Merge Schedules
            <v-icon right>mdi-call-merge</v-icon>
          </v-btn>
          <v-btn v-if="mergedView" color="primary" @click="mergedView = false">
            Split Schedules
            <v-icon right>mdi-call-split</v-icon>
          </v-btn>
        </div>
      </h1>
    </template>
    <div v-if="authorSchedules && authorSchedules.length" class="all-schedules__wrapper">
      <template v-if="mergedView">
        <ScheduleSingle
          :authors="mergedAuthorData"
          :events="mergedEvents"
          :eventHeaders="headers"
          :ambassador="targetAmbassadorData"
          :formattedDateRange="formattedDateRange"
          :mergedView="true"
        />
      </template>
      <template v-else>
        <div
          v-for="(schedule, i) in authorSchedules"
          :key="`schedule-${i}`"
          class="all-schedules__single-wrapper"
          :style="i < authorSchedules.length - 1 ? 'page-break-after: always' : ''"
        >
          <ScheduleSingle
            :targetAuthorData="schedule.authorData"
            :events="schedule.scheduleData"
            :eventHeaders="eventHeaders(adminView, schedule.authorData, this.userRole === 'admin')"
            :ambassador="schedule.ambassadorData"
            :formattedDateRange="formattedDateRange"
            :rsvpTotals="rsvpTotals"
          />
        </div>
      </template>
    </div>
    <!-- Original -->
    <ScheduleSingle
      v-else-if="targetAuthorData || !$route.query.author"
      :targetAuthorData="targetAuthorData"
      :events="filteredEvents"
      :eventHeaders="headers"
      :ambassador="myAmbassador"
      :formattedDateRange="formattedDateRange"
      :rsvpTotals="rsvpTotals"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import ScheduleViewOptions from '@/components/ScheduleViewOptions'
import ScheduleSingle from '@/components/ScheduleSingle'

import { httpsCallable } from 'firebase/functions'

export default {
  name: 'Schedule',
  components: {
    ScheduleViewOptions,
    ScheduleSingle
  },
  async created () {
    const authorId = this.$route.query.author
    if (authorId) {
      console.log('Loading events for author', authorId)
      try {
        this.targetAuthorData = await this.getTargetAuthor(authorId)
      } catch (err) {
        console.log(err)
      }
    }

    const ambassadorId = this.$route.query.ambassador
    if (ambassadorId) {
      console.log('Loading events for ambassador', ambassadorId)
      try {
        this.targetAmbassadorData = await this.getTargetAmbassador(ambassadorId)
      } catch (err) {
        console.log(err)
      }
      if (this.targetAmbassadorData?.authors) {
        console.log('Num authors:', this.targetAmbassadorData.authors.length)
        for (let i = 0; i < this.targetAmbassadorData.authors.length; i++) {
          const targetId = this.targetAmbassadorData.authors[i]
          console.log('Target Author ID', targetId)
          const res = await httpsCallable(this.fbFunctions, 'getMyEvents')({ authorId: targetId })
          const authorData = await this.getTargetAuthor(targetId)
          const amRes = await httpsCallable(this.fbFunctions, 'getMyAmbassador')({ authorId: targetId })
          this.authorSchedules.push({
            authorData: authorData,
            scheduleData: res.data,
            ambassadorData: amRes.data
          })
          // Format / expand event data as necessary
          res.data.forEach((e) => {
            e.author = authorData
          })
          this.mergedEvents = res.data.concat(this.mergedEvents)
          this.mergedAuthorData.push(authorData)
        }
        console.log('Merged Authors:', this.mergedAuthorData)
        console.log('Merged Events:', this.mergedEvents)
      }
    }
    await this.globalFlagsListener()
    console.log('My Events', this.myEvents)
  },
  async mounted () {
    setTimeout(async () => {
      await this.authorsListener()
    }, 0)
  },
  data: () => ({
    targetAuthorData: null,
    targetAmbassadorData: null,
    authorSchedules: [], // For displaying multiple schedules at once (for an ambassador)
    mergedView: false,
    mergedAuthorData: [],
    mergedEvents: [],
    showHiddenEvents: false
  }),
  computed: {
    ...mapState(['firestore', 'globalSettings', 'fbFunctions']),
    ...mapGetters([
      'userRole',
      'myEvents',
      'numHiddenEvents',
      'myAmbassador',
      'eventHeaders',
      'formattedDateRange'
    ]),
    filteredEvents () {
      // If in global view, only show events for which hideOnGlobalSchedule is not set / false
      if (this.myEvents && this.globalView && !this.showHiddenEvents) {
        return this.myEvents.filter((event) => !event.hideOnGlobalSchedule)
      } else {
        return this.myEvents
      }
    },
    adminView () {
      return this.userRole === 'admin' || this.userRole === 'viewer'
    },
    ambassadorView () {
      return !!this.targetAmbassadorData
    },
    // If we're not viewing an ambassador or author
    globalView () {
      return !this.targetAuthorData && !this.targetAmbassadorData
    },
    headers () {
      return this.eventHeaders(this.adminView, this.targetAuthorData, this.userRole === 'admin')
    },
    rsvpTotals () {
      const totals = {}
      if (!this.myEvents) return totals
      this.myEvents.forEach((event) => {
        if (event.rsvps) {
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(event.rsvps)) {
            if (value === 'yes') {
              if (totals[event.id]) {
                totals[event.id]++
              } else {
                totals[event.id] = 1
              }
            }
          }
        }
        if (event.plusOneRsvps) {
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(event.plusOneRsvps)) {
            if (value === 'yes') {
              if (totals[event.id]) {
                totals[event.id]++
              } else {
                totals[event.id] = 1
              }
            }
          }
        }
      })
      console.log('RSVP Totals:', totals)
      return totals
    }
  },
  methods: {
    ...mapActions([
      'getTargetAuthor',
      'getTargetAmbassador',
      'globalFlagsListener',
      'authorsListener'
    ])
  }
}
</script>
